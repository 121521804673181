














import Vue from 'vue'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'
import {
    IStateDashboardUserNewJobSpecificationDetails,
    IUserSkillConfig,
    IUserSkillProfile,
} from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { REPOSITORY_USER_SKILLS } from '@/repositiories'

const { mapState, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'BtnImportMenu',

    data: () => ({
        isRequestInProgress: false,
    }),

    computed: {
        ...(mapState(['specification']) as MapStateToComputed<
            IStateDashboardUserNewJobSpecificationDetails
        >),
    },

    methods: {
        ...mapActions({
            createPreferredSkill:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_USER_SKILL,
        }),

        async importSkillsFromProfile() {
            this.isRequestInProgress = true

            try {
                const userSkills = await REPOSITORY_USER_SKILLS.fetchUserSkills()

                await this.createSkills(this.excludeCurrentSkills(userSkills))
            } catch (error) {}

            this.isRequestInProgress = false
        },

        excludeCurrentSkills(userSkills: IUserSkillProfile[]): IUserSkillProfile[] {
            const currentSkillUuids = this.specification!.preferredSkills.map(
                ({ skillUuid }) => skillUuid
            )

            return userSkills.filter(({ skillUuid }) => !currentSkillUuids.includes(skillUuid))
        },

        async createSkills(userSkills: IUserSkillProfile[]) {
            const skillConfigs: IUserSkillConfig[] = userSkills.map((userSkill) => {
                const { skillName, skillUuid, categoryName, categoryUuid } = userSkill

                return { skillName, skillUuid, categoryName, categoryUuid }
            })

            return await Promise.all(skillConfigs.map(this.createPreferredSkill))
        },
    },
})
