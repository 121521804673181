




















import Vue from 'vue'
import { faDungeon } from '@fortawesome/free-solid-svg-icons'
import {
    BaseCard,
    BaseCardTitle,
    BaseCardBody,
    BaseList,
    BaseChipContainer,
} from '@/components'
import { ICompany, IUserNewJobSpecificationDetails } from '@/types'
import { FieldAddCompany, Company } from './components'

export default Vue.extend({
    name: 'CardBlacklistedCompanies',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseList,
        BaseChipContainer,
        FieldAddCompany,
        Company,
    },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },

    data: () => ({ faDungeon }),

    computed: {
        companies(): ICompany[] {
            return this.specification.blacklistedCompanies
        },
    },
})
