






import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { BaseUserSkillEditable } from '@/components'
import { IUserSkill } from '@/types'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'Skill',

    components: { BaseUserSkillEditable },

    props: {
        userSkill: {
            type: Object as () => IUserSkill,
            required: true,
        },
    },

    methods: {
        ...mapActions({
            deleteUserSkill:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_PREFERRED_USER_SKILL,
        }),

        async remove() {
            try {
                await this.deleteUserSkill(this.userSkill.uuid)
            } catch (error) {}
        },
    },
})
