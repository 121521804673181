












import Vue from 'vue'
import { getFormattedDateTime } from '@/utils'
import { faCalendarPlus, faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import { BaseListItemProps, BaseListItemProp } from '@/components'
import { IUserNewJobSpecification } from '@/types'

export default Vue.extend({
    name: 'SpecificationProps',

    components: { BaseListItemProps, BaseListItemProp },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecification,
            required: true,
        },
    },

    data: () => ({ faCalendarPlus, faCalendarCheck }),

    computed: {
        hasBeenUpdated(): boolean {
            return this.specification.timestampCreated !== this.specification.timestampUpdated
        },

        timestampCreatedFormatted(): string {
            return getFormattedDateTime(this.specification.timestampCreated)
        },

        timestampUpdatedFormatted(): string {
            return getFormattedDateTime(this.specification.timestampUpdated)
        },
    },
})
