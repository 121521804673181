










import Vue from 'vue'
import { IUserNewJobSpecification } from '@/types'
import { SpecificationListItem } from './components'

export default Vue.extend({
    name: 'SpecificationList',

    components: { SpecificationListItem },

    props: {
        specifications: {
            type: Array as () => IUserNewJobSpecification[],
            required: true,
        },
    },
})
