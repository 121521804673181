



















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS, STORE_MODULE } from '@/enums'
import { IStateDashboardUserNewJobSpecifications } from '@/types'
import { BaseCard, BaseCardBody, BaseCardTitle, BaseSpinner } from '@/components'
import {
    BtnAddNewJobSpecification,
    SpecificationList,
    SpecificationListPlaceholder,
} from './components'

const { mapState, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATIONS
)

export default Vue.extend({
    name: 'DashboardUserNewJobSpecifications',

    components: {
        BaseSpinner,
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BtnAddNewJobSpecification,
        SpecificationList,
        SpecificationListPlaceholder,
    },

    computed: {
        ...(mapState(['specifications']) as MapStateToComputed<
            IStateDashboardUserNewJobSpecifications
        >),
    },

    async created() {
        try {
            await this.fetchAndSetSpecifications()
        } catch (error) {}
    },

    methods: {
        ...mapActions({
            fetchAndSetSpecifications:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.FETCH_AND_SET_SPECIFICATIONS,
        }),
    },
})
