




import Vue from 'vue'
import { BaseBtn } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS, ROUTE, STORE_MODULE } from '@/enums'
import { IUserNewJobSpecificationConfig } from '@/types'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATIONS
)

export default Vue.extend({
    name: 'BtnAddNewJobSpecification',

    components: { BaseBtn },

    data: () => ({
        isRequestInProgress: false,
    }),

    computed: {
        attrs(): Record<string, unknown> {
            return {
                variant: 'success',
                size: 'sm',
                icon: faPlus,
                loading: this.isRequestInProgress,
                ...this.$attrs,
            }
        },
    },

    methods: {
        ...mapActions({
            createSpecification:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.CREATE_SPECIFICATION,
        }),

        async createAndRedirect() {
            this.isRequestInProgress = true

            try {
                const config: Pick<IUserNewJobSpecificationConfig, 'name'> = {
                    name: 'My New job specification',
                }

                const { uuid: specificationUuid } = await this.createSpecification(config)

                this.$router.push({
                    name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATION_DETAILS,
                    params: { specificationUuid },
                })
            } catch (error) {}

            this.isRequestInProgress = false
        },
    },
})
