









import Vue from 'vue'
import { BaseFieldTypeaheadAddUserSkill } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'
import { IUserSkill, IUserSkillConfig } from '@/types'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'FieldAddSkill',

    components: { BaseFieldTypeaheadAddUserSkill },

    props: {
        userSkills: {
            type: Array as () => IUserSkill[],
            required: true,
        },
    },

    data: () => ({
        isCreatingSkill: false,
    }),

    methods: {
        ...mapActions({
            createUserSkillAction:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_USER_SKILL,
        }),

        async createUserSkill(config: IUserSkillConfig) {
            this.isCreatingSkill = true

            try {
                await this.createUserSkillAction(config)
            } catch (error) {}

            this.focusField()
            this.isCreatingSkill = false
        },

        focusField(): void {
            const typeahead = this.$refs.typeahead as any

            typeahead.focus()
        },
    },
})
