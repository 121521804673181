






import { BaseBtn } from '@/components'
import { ROUTE } from '@/enums'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { Location } from 'vue-router'

export default Vue.extend({
    name: 'BtnBackToList',

    components: { BaseBtn },

    data: () => ({ faAngleLeft }),

    computed: {
        routeSpecifications(): Location {
            return { name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATIONS }
        },
    },
})
