import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'
import { IUserNewJobSpecificationDetails } from '@/types'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export const updateableSpecificationField = Vue.extend({
    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
        componentKey: Math.random(),
    }),

    methods: {
        ...mapActions({
            updateSpecification:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION,
        }),
    },
})
