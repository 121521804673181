







import Vue from 'vue'
import { BaseBtnContainer } from '@/components'
import { BtnBackToList, BtnDelete } from './components'
import { IUserNewJobSpecificationDetails } from '@/types'

export default Vue.extend({
    name: 'Actions',

    components: { BaseBtnContainer, BtnBackToList, BtnDelete },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },
})
