














import Vue from 'vue'
import { ICompany } from '@/types'
import { BaseFieldTypeaheadCompany } from '@/components'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'
import { createNamespacedHelpers } from 'vuex'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'FieldAddCompany',

    components: { BaseFieldTypeaheadCompany },

    props: {
        companies: {
            type: Array as () => ICompany[],
            required: true,
        },
    },

    data: () => ({
        isCreatingPreferredCompany: false,
        faPlus,
    }),

    methods: {
        ...mapActions({
            createPreferredCompanyAction:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_COMPANY,
        }),

        async createPreferredCompany(city: ICompany) {
            this.isCreatingPreferredCompany = true

            try {
                await this.createPreferredCompanyAction(city)
            } catch (error) {}

            this.focusField()
            this.isCreatingPreferredCompany = false
        },

        focusField(): void {
            const typeahead = this.$refs.typeahead as any

            typeahead.focus()
        },
    },
})
