






import Vue from 'vue'
import { BaseChip } from '@/components'
import { ICity } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'City',

    components: { BaseChip },

    props: {
        city: {
            type: Object as () => ICity,
            required: true,
        },
    },

    computed: {
        title(): string {
            return `${this.city.name} (${this.city.country})`
        },
    },

    methods: {
        ...mapActions({
            deletePreferredCity:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_PREFERRED_CITY,
        }),

        remove() {
            try {
                this.deletePreferredCity(this.city.id)
            } catch (error) {}
        },
    },
})
