




import Vue from 'vue'
import { BaseBtnDelete } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS, ROUTE, STORE_MODULE } from '@/enums'
import { IUserNewJobSpecificationDetails } from '@/types'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATIONS
)

export default Vue.extend({
    name: 'BtnDelete',

    components: { BaseBtnDelete },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
    }),

    methods: {
        ...mapActions({
            deleteSpecificationAction:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.DELETE_SPECIFICATION,
        }),

        async deleteSpecification() {
            this.isRequestInProgress = true

            try {
                await this.deleteSpecificationAction(this.specification.uuid)

                this.$router.replace({ name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATIONS })
            } catch (error) {}

            this.isRequestInProgress = false
        },
    },
})
