












import { BaseFieldTextarea } from '@/components'
import { mixins } from '@/utils'
import { updateableSpecificationField } from '../../mixins'

export default mixins(updateableSpecificationField).extend({
    name: 'FieldDescription',

    components: { BaseFieldTextarea },

    methods: {
        async updateDescription(customCriteriaDescription: string) {
            this.isRequestInProgress = true

            try {
                await this.updateSpecification({ customCriteriaDescription })
            } catch (error) {
                this.componentKey = Math.random()
            }

            this.isRequestInProgress = false
        },
    },
})
