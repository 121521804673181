
















import Vue from 'vue'
import { IUserNewJobSpecificationDetails } from '@/types'
import {
    FieldName,
    FieldSalary,
    FieldDescription,
    FieldContractTypes,
    FieldRemote,
} from './components'

export default Vue.extend({
    name: 'Details',

    components: {
        FieldName,
        FieldSalary,
        FieldDescription,
        FieldContractTypes,
        FieldRemote,
    },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },
})
