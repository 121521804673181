










import Vue from 'vue'
import { BtnAddNewJobSpecification } from '../BtnAddNewJobSpecification'

export default Vue.extend({
    name: 'SpecificationListPlaceholder',

    components: { BtnAddNewJobSpecification },
})
