





























import Vue from 'vue'
import { BaseCard, BaseCardBody, BaseCardTitle, BaseSpinner } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import {
    ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS,
    MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS,
    ROUTE,
    STORE_MODULE,
} from '@/enums'
import { IStateDashboardUserNewJobSpecificationDetails } from '@/types'
import {
    Actions,
    Details,
    CardBlacklistedCompanies,
    CardPreferredCities,
    CardPreferredCompanies,
    CardPreferredSkills,
} from './components'

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'DashboardUserNewJobSpecificationDetails',

    components: {
        BaseCard,
        BaseCardBody,
        BaseCardTitle,
        BaseSpinner,
        Actions,
        Details,
        CardBlacklistedCompanies,
        CardPreferredCities,
        CardPreferredCompanies,
        CardPreferredSkills,
    },

    props: {
        specificationUuid: String,
    },

    computed: {
        ...(mapState(['specification']) as MapStateToComputed<
            IStateDashboardUserNewJobSpecificationDetails
        >),
    },

    async created() {
        try {
            await this.fetchAndSetSpecification(this.specificationUuid)
        } catch (error) {
            this.$router.replace({ name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATIONS })
        }
    },

    beforeDestroy() {
        this.setSpecification(undefined)
    },

    methods: {
        ...mapMutations({
            setSpecification: MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.SET_SPECIFICATION,
        }),

        ...mapActions({
            fetchAndSetSpecification:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.FETCH_AND_SET_SPECIFICATION,
        }),
    },
})
