











import { BaseFieldText } from '@/components'
import { mixins } from '@/utils'
import { updateableSpecificationField } from '../../mixins'

export default mixins(updateableSpecificationField).extend({
    name: 'FieldName',

    components: { BaseFieldText },

    computed: {
        isAutofocused(): boolean {
            // focus only for new
            return this.specification.timestampCreated + 5e3 > Date.now()
        },
    },

    methods: {
        async updateName(name: string) {
            this.isRequestInProgress = true

            try {
                if (!name) throw new Error('Name cannot be empty')

                await this.updateSpecification({ name })
            } catch (error) {
                this.componentKey = Math.random()
            }

            this.isRequestInProgress = false
        },
    },
})
