

















import Vue from 'vue'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { IUserNewJobSpecificationDetails, IUserSkill } from '@/types'
import { BaseCard, BaseCardTitle, BaseCardBody, BaseListUserSkills } from '@/components'
import { BtnImportMenu, FieldAddSkill, Skill } from './components'

export default Vue.extend({
    name: 'CardPreferredSkills',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseListUserSkills,
        BtnImportMenu,
        FieldAddSkill,
    },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },

    data: () => ({
        skillComponent: Skill,
        faTags,
    }),

    computed: {
        userSkills(): IUserSkill[] {
            return this.specification.preferredSkills
        },
    },
})
