






import Vue from 'vue'
import { BaseChip } from '@/components'
import { ICompany } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS
)

export default Vue.extend({
    name: 'Company',

    components: { BaseChip },

    props: {
        company: {
            type: Object as () => ICompany,
            required: true,
        },
    },

    computed: {
        title(): string {
            return this.company.name
        },
    },

    methods: {
        ...mapActions({
            deleteBlacklistedCompany:
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_BLACKLISTED_COMPANY,
        }),

        remove() {
            try {
                this.deleteBlacklistedCompany(this.company.id)
            } catch (error) {}
        },
    },
})
