








import { BaseFieldRemoteTime } from '@/components'
import { mixins } from '@/utils'
import { updateableSpecificationField } from '../../mixins'

export default mixins(updateableSpecificationField).extend({
    name: 'FieldRemote',

    components: { BaseFieldRemoteTime },

    computed: {
        preferredRemote: {
            get(): number | null {
                return this.specification.preferredRemote
            },
            set(preferredRemote: number | null) {
                this.updateContractTypes(preferredRemote)
            },
        },
    },

    methods: {
        async updateContractTypes(preferredRemote: number | null) {
            this.isRequestInProgress = true

            try {
                await this.updateSpecification({ preferredRemote })
            } catch (error) {
                this.componentKey = Math.random()
            }

            this.isRequestInProgress = false
        },
    },
})
