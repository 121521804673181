










import Vue from 'vue'
import { BaseListItem } from '@/components'
import { IUserNewJobSpecification } from '@/types'
import { faStickyNote } from '@fortawesome/free-regular-svg-icons'
import { Location } from 'vue-router'
import { ROUTE } from '@/enums'
import { SpecificationProps } from './components'

export default Vue.extend({
    name: 'SpecificationListItem',

    components: { BaseListItem, SpecificationProps },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecification,
            required: true,
        },
    },

    data: () => ({ faStickyNote }),

    computed: {
        detailsRoute(): Location {
            return {
                name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATION_DETAILS,
                params: { specificationUuid: this.specification.uuid },
            }
        },
    },
})
