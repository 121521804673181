










import { BaseFieldMoney } from '@/components'
import { IMoney } from '@/types'
import { mixins } from '@/utils'
import { updateableSpecificationField } from '../../mixins'

export default mixins(updateableSpecificationField).extend({
    name: 'FieldSalary',

    components: { BaseFieldMoney },

    computed: {
        preferredSalary: {
            get(): IMoney {
                return this.specification.preferredSalary
            },
            set(preferredSalary: IMoney) {
                this.updateSalary(preferredSalary)
            },
        },
    },

    methods: {
        async updateSalary(preferredSalary: IMoney) {
            this.isRequestInProgress = true

            try {
                await this.updateSpecification({ preferredSalary })
            } catch (error) {
                this.componentKey = Math.random()
            }

            this.isRequestInProgress = false
        },
    },
})
