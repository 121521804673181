
















import Vue from 'vue'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import {
    BaseCard,
    BaseCardTitle,
    BaseCardBody,
    BaseList,
    BaseChipContainer,
} from '@/components'
import { ICity, IUserNewJobSpecificationDetails } from '@/types'
import { FieldAddCity, City } from './components'

export default Vue.extend({
    name: 'CardPreferredCities',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseList,
        BaseChipContainer,
        FieldAddCity,
        City,
    },

    props: {
        specification: {
            type: Object as () => IUserNewJobSpecificationDetails,
            required: true,
        },
    },

    data: () => ({ faMapMarkerAlt }),

    computed: {
        cities(): ICity[] {
            return this.specification.preferredCities
        },
    },
})
