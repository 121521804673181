








import { BaseFieldContractTypes } from '@/components'
import { mixins } from '@/utils'
import { updateableSpecificationField } from '../../mixins'

export default mixins(updateableSpecificationField).extend({
    name: 'FieldContractTypes',

    components: { BaseFieldContractTypes },

    computed: {
        preferredContractTypeIds: {
            get(): number[] {
                return this.specification.preferredContractTypeIds
            },
            set(preferredContractTypeIds: number[]) {
                this.updateContractTypes(preferredContractTypeIds)
            },
        },
    },

    methods: {
        async updateContractTypes(preferredContractTypeIds: number[]) {
            this.isRequestInProgress = true

            try {
                await this.updateSpecification({ preferredContractTypeIds })
            } catch (error) {
                this.componentKey = Math.random()
            }

            this.isRequestInProgress = false
        },
    },
})
